import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MergeTypeOutlinedIcon from '@material-ui/icons/MergeTypeOutlined';

import axios from 'axios';
import pageData from 'src/pageData';
import { AppContext } from 'src/App';

const useStyles = makeStyles((theme) => ({
  root: {},
  buttonOpositive: {
    color: '#3e2723',
    background: '#efebe9',
    marginLeft: theme.spacing(1)
  },
  buttonSame: {
    color: '#0d47a1',
    background: '#e3f2fd',
    marginLeft: theme.spacing(1)
  },
  buttonClose: {
    color: '#b71c1c',
    background: '#fce4ec',
    marginLeft: theme.spacing(1)
  }
}));

const sizes = [
  {
    value: '0.01',
    label: '0.01'
  },
  {
    value: '0.02',
    label: '0.02'
  },
  {
    value: '0.03',
    label: '0.03'
  },
  {
    value: '0.04',
    label: '0.04'
  },
  {
    value: '0.05',
    label: '0.05'
  },
  {
    value: '0.06',
    label: '0.06'
  },
  {
    value: '0.07',
    label: '0.07'
  },
  {
    value: '0.08',
    label: '0.08'
  },
  {
    value: '0.09',
    label: '0.09'
  },
  {
    value: '0.10',
    label: '0.10'
  }
];

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();
  const [data] = useContext(AppContext);
  const authUser = JSON.parse(data);

  const [size, setSize] = useState(0.01);

  const handleChange = (event) => {
    setSize(event.target.value);
  };

  const fetchFollow = async () => {
    const userData = new FormData();
    userData.append('userId', authUser.userId);
    userData.append('magicNumber', rest.magic);
    userData.append('size', size);

    await axios.post(`${pageData.apiUrl}/api/follow-ea`, userData)
      .then((res) => {
        if (res.status === 200) {
          rest.closeMagic();
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        mb={3}
      >
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <h1>{`Magic Number: ${rest.magic}`}</h1>
              </Grid>
              <Grid
                item
                xs={6}
              >
                <Box
                  display="flex"
                  justifyContent="flex-end"
                >
                  <TextField
                    label="Size"
                    name="size"
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={size}
                    variant="outlined"
                  >
                    {sizes.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                  <Button
                    className={classes.buttonSame}
                    startIcon={<MergeTypeOutlinedIcon />}
                    onClick={() => fetchFollow()}
                  >
                    FOLLOW
                  </Button>
                  <Button
                    className={classes.buttonClose}
                    startIcon={<CloseIcon />}
                    onClick={() => rest.closeMagic()}
                  >
                    CLOSE
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
