import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import BlockIcon from '@material-ui/icons/Block';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

import axios from 'axios';
import pageData from 'src/pageData';
import { AppContext } from 'src/App';

const useStyles = makeStyles((theme) => ({
  root: {},
  buttonOpositive: {
    color: '#3e2723',
    background: '#efebe9',
    marginRight: theme.spacing(1)
  },
  buttonUnfollow: {
    color: '#263238',
    background: '#eceff1',
    marginRight: theme.spacing(3)
  },
  buttonDisable: {
    color: '#ff6f00',
    background: '#fff8e1',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(3)
  },
  buttonEnable: {
    color: '#1b5e20',
    background: '#e8f5e9',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(3)
  },
  buttonClose: {
    color: '#b71c1c',
    background: '#fce4ec'
  }
}));

const sizes = [
  {
    value: '0.01',
    label: '0.01'
  },
  {
    value: '0.02',
    label: '0.02'
  },
  {
    value: '0.03',
    label: '0.03'
  },
  {
    value: '0.04',
    label: '0.04'
  },
  {
    value: '0.05',
    label: '0.05'
  },
  {
    value: '0.06',
    label: '0.06'
  },
  {
    value: '0.07',
    label: '0.07'
  },
  {
    value: '0.08',
    label: '0.08'
  },
  {
    value: '0.09',
    label: '0.09'
  },
  {
    value: '0.10',
    label: '0.10'
  }
];

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();
  const [data] = useContext(AppContext);
  const authUser = JSON.parse(data);
  const [active, setActive] = useState(false);
  const [size, setSize] = useState('0.01');

  const fetchFollow = async () => {
    const userData = new FormData();
    userData.append('userId', authUser.userId);
    userData.append('magicNumber', rest.magic);

    await axios.post(`${pageData.apiUrl}/api/get-follow-detail`, userData)
      .then((res) => {
        if (res.status === 200) {
          setSize(res.data.detail.size);
          setActive(res.data.detail.active);
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchFollow();
  }, []);

  const handleChange = async (event) => {
    setSize(event.target.value);
    const userData = new FormData();
    userData.append('userId', authUser.userId);
    userData.append('magicNumber', rest.magic);
    userData.append('size', event.target.value);

    await axios.post(`${pageData.apiUrl}/api/update-ea-size`, userData)
      .then((res) => {
        if (res.status === 200) {
          setSize(event.target.value);
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const fetchEnable = async () => {
    const userData = new FormData();
    userData.append('userId', authUser.userId);
    userData.append('magicNumber', rest.magic);

    await axios.post(`${pageData.apiUrl}/api/enable-ea`, userData)
      .then((res) => {
        if (res.status === 200) {
          rest.closeMagic();
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const fetchDisable = async () => {
    const userData = new FormData();
    userData.append('userId', authUser.userId);
    userData.append('magicNumber', rest.magic);

    await axios.post(`${pageData.apiUrl}/api/disable-ea`, userData)
      .then((res) => {
        if (res.status === 200) {
          rest.closeMagic();
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const fetchUnfollow = async () => {
    const userData = new FormData();
    userData.append('userId', authUser.userId);
    userData.append('magicNumber', rest.magic);

    await axios.post(`${pageData.apiUrl}/api/unfollow-ea`, userData)
      .then((res) => {
        if (res.status === 200) {
          rest.closeMagic();
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        mb={3}
      >
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <h2>{`Magic Number: ${rest.magic}`}</h2>
              </Grid>
              <Grid
                item
                xs={6}
              >
                <Box
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Button
                    className={classes.buttonUnfollow}
                    startIcon={<CloseIcon />}
                    onClick={() => fetchUnfollow()}
                  >
                    UNFOLLOW
                  </Button>
                  <TextField
                    label="Size"
                    name="size"
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={size}
                    variant="outlined"
                  >
                    {sizes.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                  {
                    active === true
                      ? (
                        <Button
                          className={classes.buttonDisable}
                          startIcon={<BlockIcon />}
                          onClick={() => fetchDisable()}
                        >
                          DISABLED
                        </Button>
                      )
                      : (
                        <Button
                          className={classes.buttonEnable}
                          startIcon={<PlayCircleOutlineIcon />}
                          onClick={() => fetchEnable()}
                        >
                          ENABLE
                        </Button>
                      )
                  }
                  <Button
                    className={classes.buttonClose}
                    startIcon={<CloseIcon />}
                    onClick={() => rest.closeMagic()}
                  >
                    CLOSE
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
