import React, { useEffect, useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Box,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';

import { AppContext } from 'src/App';
import axios from 'axios';
import pageData from 'src/pageData';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.green[600],
    height: 56,
    width: 56
  },
  positiveValue: {
    color: colors.green[900]
  },
  negativeValue: {
    color: colors.red[900]
  }
}));

const AccountBalance = ({ className, ...rest }) => {
  const classes = useStyles();
  const [user] = useContext(AppContext);
  const userData = JSON.parse(user);

  const [accNumber, setAccNumber] = useState(0);
  const [name, setName] = useState('');
  const [currency, setCurrency] = useState('');
  const [leverage, setLeverage] = useState(0);

  const fetchProfitLatest = async () => {
    await axios.get(`${pageData.apiUrl}/api/get-user-account/${userData.userId}`)
      .then((res) => {
        if (res.status === 200) {
          setAccNumber(res.data.account.accountNumber);
          setName(res.data.account.accountName);
          setCurrency(res.data.account.currency);
          setLeverage(res.data.account.leverage);
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchProfitLatest();
  }, []);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              ACCOUNT INFO
            </Typography>
            <Box ml={2}>
              <Typography
                variant="h3"
              >
                Acc #:
                {accNumber}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <ContactSupportOutlinedIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box
          mt={3}
          textAlign="center"
        >
          <Typography
            variant="body1"
          >
            {name}
          </Typography>
          <Typography
            variant="body1"
          >
            {`Currency: ${currency}`}
          </Typography>
          <Typography
            variant="body1"
          >
            {`Leverage: 1/${leverage}`}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

AccountBalance.propTypes = {
  className: PropTypes.string
};

export default AccountBalance;
