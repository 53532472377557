import React, { useState, useContext } from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';

import { AppContext } from 'src/App';
import axios from 'axios';
import pageData from 'src/pageData';

import Toolbar from './Toolbar';
import Experts from './Experts';
import Transactions from './Transactions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Projection = () => {
  const classes = useStyles();
  const [width] = useState(window.innerWidth);
  const [user] = useContext(AppContext);
  const userData = JSON.parse(user);
  const [profits, setProfits] = useState([]);
  const [times, setTimes] = useState([]);

  const showSelectedReport = async (value) => {
    if (value.status === 'dayly') {
      await axios.get(`${pageData.apiUrl}/api/sumary-eas-transactions-by-day/${userData.userId}`)
        .then((res) => {
          if (res.status === 200) {
            setProfits(res.data.profitList);
            setTimes(res.data.timeList);
          }
        })
        .catch((err) => console.log(err));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    } else if (value.status === 'last') {
      await axios.get(`${pageData.apiUrl}/api/sumary-eas-transactions/${userData.userId}`)
        .then((res) => {
          if (res.status === 200) {
            setProfits(res.data.profitList);
            setTimes(res.data.timeList);
          }
        })
        .catch((err) => console.log(err));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    } else {
      await axios.get(`${pageData.apiUrl}/api/sumary-eas-transactions-by-week/${userData.userId}`)
        .then((res) => {
          if (res.status === 200) {
            setProfits(res.data.profitList);
            setTimes(res.data.timeList);
          }
        })
        .catch((err) => console.log(err));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  };

  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      {width > 500
        ? (
          <Container maxWidth={false}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xl={2}
                lg={3}
                md={3}
                sm={4}
                xs={12}
              >
                <Experts />
              </Grid>
              <Grid
                item
                xl={10}
                lg={9}
                md={9}
                sm={8}
                xs={12}
              >
                <Toolbar showReport={(status) => showSelectedReport(status)} />
                <Transactions profits={profits} times={times} />
              </Grid>
            </Grid>
          </Container>
        )
        : (
          <Container maxWidth={false}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <Experts />
              </Grid>
              <Grid
                item
                lg={8}
                md={12}
                xl={9}
                xs={12}
              >
                <Toolbar showReport={(status) => showSelectedReport(status)} />
                <Transactions profits={profits} times={times} />
              </Grid>
            </Grid>
          </Container>
        )}
    </Page>
  );
};

export default Projection;
