import React, { useEffect, useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles
} from '@material-ui/core';

import { AppContext } from 'src/App';
import axios from 'axios';
import pageData from 'src/pageData';

const useStyles = makeStyles(() => ({
  root: {}
}));

const LatestTransactions = ({ className, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [user] = useContext(AppContext);
  const userData = JSON.parse(user);
  const [profits, setProfits] = useState([]);
  const [times, setTimes] = useState([]);
  const [loading] = useState(0);

  const data = {
    labels: times,
    datasets: [
      {
        fill: false,
        lineTension: 0.2,
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 1.5,
        data: profits
      }
    ]
  };

  const options = {
    animation: false,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    pointRadius: 0.2,
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true
          },
          gridLines: {
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const fetchLatestTransactions = async () => {
    await axios.get(`${pageData.apiUrl}/api/get-last-100/${userData.userId}`)
      .then((res) => {
        if (res.status === 200) {
          setProfits(res.data.profitList);
          setTimes(res.data.timeList);
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchLatestTransactions();
  }, [loading]);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        title="Latest Transactions"
      />
      <Divider />
      <CardContent>
        <Box
          height={560}
          position="relative"
        >
          <Line
            data={data}
            options={options}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

LatestTransactions.propTypes = {
  className: PropTypes.string
};

export default LatestTransactions;
