import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles
} from '@material-ui/core';

import axios from 'axios';
import pageData from 'src/pageData';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';

const useStyles = makeStyles(({
  root: {}
}));

const AccountNumber = ({ className, ...rest }) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    accountNumber: ''
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleUpdateBearer = async () => {
    const userData = new FormData();
    userData.append('accountNumber', values.accountNumber);

    await axios.post(`${pageData.apiUrl}/api/update-account-number`, userData)
      .then((res) => {
        if (res.status === 200) {
          ToastsStore.success('Account Number successfully updated!');
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <form
      className={clsx(classes.root, className)}
      {...rest}
    >
      <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
      <Card>
        <CardHeader
          subheader="Update account number"
          title="Account Number"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Account Number"
            margin="normal"
            name="accountNumber"
            onChange={handleChange}
            type="text"
            value={values.accountNumber}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleUpdateBearer()}
          >
            Update
          </Button>
        </Box>
      </Card>
    </form>
  );
};

AccountNumber.propTypes = {
  className: PropTypes.string
};

export default AccountNumber;
