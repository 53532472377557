import React, { useContext } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Divider from '@material-ui/core/Divider';

import axios from 'axios';
import pageData from 'src/pageData';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';

import setAuthToken from 'src/utils/setAuthToken';
import { AppContext } from 'src/App';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [, setUser] = useContext(AppContext);

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().min(5).max(255).required('Password is required')
            })}
            onSubmit={(loginData) => {
              const userData = new FormData();
              userData.append('email', loginData.email);
              userData.append('password', loginData.password);

              axios.post(`${pageData.apiUrl}/api/login`, userData)
                .then((res) => {
                  if (res.status === 200) {
                    if (res.data.success) {
                      localStorage.setItem('jwtToken', res.data.token);
                      setAuthToken(res.data.token);
                      setUser(JSON.stringify({
                        name: res.data.user.name,
                        userId: res.data.user.userId,
                        userType: res.data.user.userType
                      }));
                      navigate('/app/dashboard', { replace: true });
                    } else {
                      ToastsStore.error(res.data.message);
                    }
                  }
                })
                .catch((err) => console.log(err));
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Sign in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Sign in on the internal platform
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
                <Box
                  mb={3}
                >
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    Don&apos;t have an account?
                    {' '}
                    <Link
                      component={RouterLink}
                      to="/register"
                      variant="h6"
                    >
                      Sign up
                    </Link>
                  </Typography>
                </Box>
                <Divider variant="middle" />
                <Box
                  mt={2}
                  display="flex"
                  justifyContent="center"
                >
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    Don&apos;t remember your password?
                    {' '}
                    <Link
                      component={RouterLink}
                      to="/forgot"
                      variant="h6"
                    >
                      Forgot Password
                    </Link>
                  </Typography>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
