import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className, transactions, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={950}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Ticket Number
                </TableCell>
                <TableCell>
                  Magic Number
                </TableCell>
                <TableCell>
                  Temporality
                </TableCell>
                <TableCell>
                  Order Type
                </TableCell>
                <TableCell>
                  Profit
                </TableCell>
                <TableCell>
                  Close Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions && transactions.map((transaction) => (
                <TableRow
                  hover
                  key={transaction.id}
                >
                  <TableCell>
                    {transaction.ticketNumber}
                  </TableCell>
                  <TableCell>
                    {transaction.magicNumber}
                  </TableCell>
                  <TableCell>
                    {transaction.temporality}
                  </TableCell>
                  <TableCell>
                    {transaction.orderType === '0' ? 'Sell' : 'Buy'}
                  </TableCell>
                  <TableCell>
                    {transaction.profit}
                  </TableCell>
                  <TableCell>
                    {moment(transaction.closeTime).format('MM/DD HH:mm')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  transactions: PropTypes.array.isRequired
};

export default Results;
