import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import useLocalState from 'src/utils/hooks';

const useStyles = makeStyles((theme) => ({
  root: {},
  buttonClear: {
    color: '#145A32',
    background: '#82E0AA',
    height: 54
  },
  symbolInput: {
    marginRight: theme.spacing(2),
    width: 110
  },
  teporalityInput: {
    marginRight: theme.spacing(2),
    width: 110
  },
  tradesInput: {
    marginRight: theme.spacing(2),
    width: 110
  },
  profitInput: {
    marginRight: theme.spacing(2),
    width: 110
  },
}));

const temporalities = [
  {
    value: 'M5',
    label: 'M5'
  },
  {
    value: 'M15',
    label: 'M15'
  },
  {
    value: 'H1',
    label: 'H1'
  }
];

const tradeList = [
  {
    value: '5',
    label: '>= 5'
  },
  {
    value: '10',
    label: '>= 10'
  },
  {
    value: '15',
    label: '>= 15'
  },
  {
    value: '20',
    label: '>= 20'
  }
];

const profitFactors = [
  {
    value: '80',
    label: '>= 80'
  },
  {
    value: '60',
    label: '>= 60'
  },
  {
    value: '50',
    label: '>= 50'
  },
  {
    value: '30',
    label: '>= 30'
  }
];

const MainToolbar = ({ className, ...rest }) => {
  const classes = useStyles();

  const [show, setShow] = useState(true);
  const [search, setSearch] = useState('');

  const [temporality, setTemporality] = useLocalState('M15');
  const [trades, setTrades] = useState('10');
  const [profitFactor, setProfitFactor] = useState('50');

  const handleSearch = async (text) => {
    rest.searchmagic(text.target.value);
    setSearch(text.target.value);
    if (text.target.value.length >= 3) {
      setShow(false);
    }
  };

  const handleClearSearch = () => {
    setSearch('');
    setShow(true);
  };

  const handleChangeTemporality = (event) => {
    rest.searchbytemporality(event.target.value);
    setTemporality(event.target.value);
  };

  const handleChangeTrades = (event) => {
    rest.searchbytrades(event.target.value);
    setTrades(event.target.value);
  };

  const handleChangeProfitFactor = (event) => {
    rest.searchbyprofitfactor(event.target.value);
    setProfitFactor(event.target.value);
  };

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        mb={3}
      >
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                        >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Magic Number"
                  variant="outlined"
                  className={classes.searchBox}
                  onChange={(text) => handleSearch(text)}
                  value={search}
                  type="number"
                />
              </Grid>
              <Grid item xs={1}>
                <Button
                  className={classes.buttonClear}
                  onClick={() => handleClearSearch()}
                  disabled={show}
                >
                  Clear
                </Button>
              </Grid>
              <Grid
                item
                xs={7}
              >
                <Box
                  display="flex"
                  justifyContent="flex-end"
                >

                  <TextField
                    label="Temporality"
                    name="temporality"
                    onChange={handleChangeTemporality}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={temporality}
                    variant="outlined"
                    className={classes.teporalityInput}
                    disabled={!show}
                  >
                    {temporalities.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    label="Trades"
                    name="trades"
                    onChange={handleChangeTrades}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={trades}
                    variant="outlined"
                    className={classes.tradesInput}
                    disabled={!show}
                  >
                    {tradeList.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    label="Profit Factor"
                    name="profitFactor"
                    onChange={handleChangeProfitFactor}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={profitFactor}
                    variant="outlined"
                    className={classes.tradesInput}
                    disabled={!show}
                  >
                    {profitFactors.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </TextField>

                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

MainToolbar.propTypes = {
  className: PropTypes.string
};

export default MainToolbar;
