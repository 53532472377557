import React from 'react';
import { Navigate } from 'react-router-dom';

import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';

import DashboardView from 'src/views/dashboard/UserView';
import FollowEAS from 'src/views/follow/FollowEAS';
import MyRobots from 'src/views/robots/MyRobots';

import AccountView from 'src/views/account/AccountView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import SettingsView from 'src/views/settings/SettingsView';
import OpenOrders from 'src/views/orders/OpenOrders';
import TransactionsListView from 'src/views/transactions/TransactionsListView';
import ByMagicNumber from 'src/views/transactions/TransactionsByMagicNumber';
import Projections from 'src/views/projections/FollowEAS';
import Web from 'src/views/page';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'follow', element: <FollowEAS /> },
      { path: 'robots', element: <MyRobots /> },
      { path: 'account', element: <AccountView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: 'transactions', element: <TransactionsListView /> },
      { path: 'statistics', element: <ByMagicNumber /> },
      { path: 'orders', element: <OpenOrders /> },
      { path: 'projections', element: <Projections /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'web', element: <Web /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: 'login', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: '404', element: <NotFoundView /> },
    ]
  }
];

export default routes;
