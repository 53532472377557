import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  buttonInactive: {
    color: '#3e2723',
    background: '#efebe9',
    marginLeft: theme.spacing(1)
  },
  buttonActive: {
    color: '#0d47a1',
    background: '#e3f2fd',
    marginLeft: theme.spacing(1)
  }
}));

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();

  const [last, setLast] = useState(0);
  const [dayly, setDayly] = useState(1);
  const [weekly, setWeekly] = useState(0);

  const fetchOption = async (option) => {
    if (option === 'last') {
      setLast(1);
      setDayly(0);
      setWeekly(0);
      rest.showReport({ status: 'last' });
    } else if (option === 'dayly') {
      setLast(0);
      setDayly(1);
      setWeekly(0);
      rest.showReport({ status: 'dayly' });
    } else {
      setLast(0);
      setDayly(0);
      setWeekly(1);
      rest.showReport({ status: 'weekly' });
    }
  };

  useEffect(() => {
    fetchOption('dayly');
  }, []);

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        mb={2}
      >
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={6}>
                <h3>Transactions</h3>
              </Grid>
              <Grid
                item
                xs={6}
              >
                <Box
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Button
                    className={last === 0 ? classes.buttonInactive : classes.buttonActive}
                    onClick={() => fetchOption('last')}
                  >
                    Last 300
                  </Button>
                  <Button
                    className={dayly === 0 ? classes.buttonInactive : classes.buttonActive}
                    onClick={() => fetchOption('dayly')}
                  >
                    Dayly
                  </Button>
                  <Button
                    className={weekly === 0 ? classes.buttonInactive : classes.buttonActive}
                    onClick={() => fetchOption('weekly')}
                  >
                    Weekly
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
