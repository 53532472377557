import React, { useEffect, useState, useContext } from 'react';
import MUIDataTable from 'mui-datatables';

import axios from 'axios';
import pageData from 'src/pageData';
import { AppContext } from 'src/App';
import { TrendingUpRounded } from '@material-ui/icons';

const columns = [
  {
    name: 'lastModify',
    label: 'Last Modify',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'magicNumber',
    label: 'Magic Number',
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: 'symbol',
    label: 'Symbol',
    options: {
      filter: TrendingUpRounded,
      sort: false,
    }
  },
  {
    name: 'temporality',
    label: 'Temporality',
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: 'size',
    label: 'Size',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'trades',
    label: 'Trades',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'winners',
    label: 'Winners',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'losers',
    label: 'Losers',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'netProfit',
    label: 'Net Profit',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'profitFactor',
    label: 'Profit Factor',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'active',
    label: 'Active',
    options: {
      filter: false,
      sort: true,
    }
  }
];

const Tables = ({ ...rest }) => {
  const [experts, setExperts] = useState([]);
  const [user] = useContext(AppContext);
  const authUser = JSON.parse(user);

  const options = {
    download: false,
    pagination: false,
    print: false,
    selectableRows: 'none',
    onRowClick: (rowData) => rest.selectMagic(rowData[1])
  };

  const fetchExperts = async () => {
    await axios.get(`${pageData.apiUrl}/api/get-follow-eas/${authUser.userId}`)
      .then((res) => {
        const data = [];
        if (res.status === 200) {
          res.data.follows.map((ea) => {
            const check = ea.followers.filter((details) => (details.userId === authUser.userId));
            data.push({
              lastModify: ea.lastModify,
              magicNumber: ea.magicNumber,
              symbol: ea.symbol,
              temporality: ea.temporality,
              size: check[0].size,
              trades: ea.trades,
              winners: ea.winners,
              losers: ea.losers,
              netProfit: ea.netProfit.toFixed(2),
              profitFactor: `${ea.profitFactor} %`,
              active: check[0].active
                ? <img src="/static/images/win.png" alt="win icon" style={{ height: 24 }} />
                : <img src="/static/images/lose.png" alt="lose icon" style={{ height: 24 }} />
            });
            return true;
          });
          setExperts(data);
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchExperts();
  }, []);

  return (
    <MUIDataTable
      title="My Followed Robots"
      data={experts}
      columns={columns}
      options={options}
    />
  );
};

export default Tables;
