import React, { useEffect, useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Box,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { AppContext } from 'src/App';
import axios from 'axios';
import pageData from 'src/pageData';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.green[600],
    height: 38,
    width: 38
  },
  positiveValue: {
    color: colors.green[900]
  },
  negativeValue: {
    color: colors.red[900]
  }
}));

const AccountBalance = ({ className, ...rest }) => {
  const classes = useStyles();
  const [user] = useContext(AppContext);
  const userData = JSON.parse(user);

  const [robots, setRobots] = useState([]);
  const [loading] = useState(0);

  const fetchProfitLatest = async () => {
    await axios.get(`${pageData.apiUrl}/api/sumary-followers-eas/${userData.userId}`)
      .then((res) => {
        if (res.status === 200) {
          setRobots(res.data.results);
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchProfitLatest();
  }, [loading]);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h5"
            >
              Experts
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <TuneOutlinedIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box>
          <TableContainer>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Magic #</TableCell>
                  <TableCell align="right">Profit %</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {robots && robots.map((row) => (
                  <TableRow key={row.magicNumber}>
                    <TableCell component="th" scope="row">
                      {row.magicNumber}
                    </TableCell>
                    <TableCell align="right">
                      {row.profitFactor}
                      %
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </CardContent>
    </Card>
  );
};

AccountBalance.propTypes = {
  className: PropTypes.string
};

export default AccountBalance;
