import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

import axios from 'axios';
import pageData from 'src/pageData';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';

import Tables from './Tables';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2)
  }
}));

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const SymbolListView = () => {
  const classes = useStyles();

  const [symbol, setSymbol] = useState({});
  const [symbols, setSymbols] = useState([]);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchSymbols = async () => {
    await axios.get(`${pageData.apiUrl}/api/get-all-symbols`)
      .then((res) => {
        if (res.status === 200) {
          setSymbols(res.data.symbols);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchSymbols();
  }, []);

  const handleUpdateInserted = () => {
    fetchSymbols();
  };

  const handleViewSymbol = (item) => {
    console.log('View symbol');
    console.log(item);
  };

  const handleEditSymbol = (item) => {
    console.log('Edit symbol');
    console.log(item);
  };

  const handleConfirmDelete = async (item) => {
    setSymbol(item);
    handleClickOpen();
  };

  const handleDeleteSymbol = async () => {
    await axios.delete(`${pageData.apiUrl}/api/delete-symbol/${symbol._id}`) // eslint-disable-line no-underscore-dangle
      .then((res) => {
        if (res.status === 200) {
          fetchSymbols();
          handleClose();
          ToastsStore.success(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Page
      className={classes.root}
      title="Symbols"
    >
      <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
      <Container maxWidth={false}>
        <Toolbar
          updateInserted={() => handleUpdateInserted()}
        />
        <Box>
          <Tables
            data={symbols}
            viewSymbol={(item) => handleViewSymbol(item)}
            editSymbol={(item) => handleEditSymbol(item)}
            deleteSymbol={(item) => handleConfirmDelete(item)}
          />
        </Box>
      </Container>

      {/* Confirm delete symbol */}
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Confirm
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete:
              {symbol.name}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteSymbol} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Page>
  );
};

export default SymbolListView;
