import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';

import axios from 'axios';
import pageData from 'src/pageData';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 380
  },
  imageMobile: {
    marginTop: 30,
    display: 'inline-block',
    maxWidth: '100%',
    width: 250
  }
}));

const ActiveAccount = () => {
  const classes = useStyles();
  const [width] = useState(window.innerWidth);
  const [message, setMessage] = useState('');

  const fetchActivate = async () => {
    const activekey = window.location.pathname.split('/')[2];
    await axios.get(`${pageData.apiUrl}/userService/activate/${activekey}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            setMessage('Congratulations. Your account is now activated.');
          } else {
            setMessage('This link was already used or is invalid.');
          }
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchActivate();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Activate Account"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Box mb={3} textAlign="center">
            <Typography
              color="textPrimary"
              variant={width > 500 ? 'h2' : 'h4'}
            >
              {message}
            </Typography>
          </Box>
          <Box textAlign="center">
            {width > 500
              ? (
                <img
                  alt="Under development"
                  className={classes.image}
                  src="/static/images/star-earning.png"
                />
              )
              : (
                <img
                  alt="Under development"
                  className={classes.imageMobile}
                  src="/static/images/star-earning.png"
                />
              )}
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default ActiveAccount;
