import React, { useEffect, useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  makeStyles,
  useTheme
} from '@material-ui/core';

import { AppContext } from 'src/App';
import axios from 'axios';
import pageData from 'src/pageData';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}));

const FollowByTemporality = ({ className, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [user] = useContext(AppContext);
  const userData = JSON.parse(user);
  const [total, setTotal] = useState(0);
  const [m15, setM15] = useState(0);
  const [m5, setM5] = useState(0);
  const [m1, setM1] = useState(0);
  const [active, setActive] = useState(0);
  // const [inactive, setInactive] = useState(0);

  const fetchByTemporality = async () => {
    await axios.get(`${pageData.apiUrl}/api/get-follow-eas/${userData.userId}`)
      .then((res) => {
        if (res.status === 200) {
          let countM1 = 0;
          let countM5 = 0;
          let countM15 = 0;
          let countActive = 0;
          res.data.follows.map((item) => {
            if (item.temporality === 'M1') {
              countM1++;
            } else if (item.temporality === 'M5') {
              countM5++;
            } else if (item.temporality === 'M15') {
              countM15++;
            }
            const status = item.followers.filter((robot) => robot.userId === userData.userId);
            if (status[0].active === true) {
              countActive++;
            }
            return true;
          });
          setTotal(res.data.follows.length);
          setM15(countM15);
          setM5(countM5);
          setM1(countM1);
          setActive(countActive);
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchByTemporality();
  }, []);

  const data = {
    datasets: [
      {
        data: [m15, m5, m1],
        backgroundColor: [
          '#00897b',
          '#1e88e5',
          colors.red[600],
          '#673ab7'
        ],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: ['M15', 'M5', 'M1']
  };

  const activeData = {
    datasets: [
      {
        data: [active, total - active],
        backgroundColor: [
          '#00c853',
          '#ff5722'
        ],
        borderWidth: 3,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: ['Active', 'Inactive']
  };

  const options = {
    animation: false,
    cutoutPercentage: 65,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const temporalities = [
    {
      title: 'M15',
      value: ((m15 * 100) / total).toFixed(0),
      color: '#1e88e5'
    },
    {
      title: 'M5',
      value: ((m5 * 100) / total).toFixed(0),
      color: colors.red[600]
    },
    {
      title: 'M1',
      value: ((m1 * 100) / total).toFixed(0),
      color: '#673ab7'
    }
  ];

  const status = [
    {
      title: 'ACTIVE',
      value: ((active * 100) / total).toFixed(0),
      color: '#00c853'
    },
    {
      title: 'INACTIVE',
      value: (((total - active) * 100) / total).toFixed(0),
      color: '#ff5722'
    }
  ];

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader title="Follow by Temporality" />
      <Divider />
      <CardContent>
        <Box
          height={200}
          position="relative"
        >
          <Doughnut
            data={data}
            options={options}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          mt={1}
        >
          {temporalities.map(({
            color,
            title,
            value
          }) => (
            <Box
              key={title}
              p={1}
              textAlign="center"
            >
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {title}
              </Typography>
              <Typography
                style={{ color }}
                variant="h3"
              >
                {value}
                %
              </Typography>
            </Box>
          ))}
        </Box>
        <Box
          height={100}
          position="relative"
          mt={3}
        >
          <Doughnut
            data={activeData}
            options={options}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          mt={1}
        >
          {status.map(({
            color,
            title,
            value
          }) => (
            <Box
              key={title}
              p={1}
              textAlign="center"
            >
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {title}
              </Typography>
              <Typography
                style={{ color }}
                variant="h3"
              >
                {value}
                %
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

FollowByTemporality.propTypes = {
  className: PropTypes.string
};

export default FollowByTemporality;
