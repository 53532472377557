import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import MUIDataTable from 'mui-datatables';

import axios from 'axios';
import pageData from 'src/pageData';
import { AppContext } from 'src/App';

import NothingOpen from './NothingOpen';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const columns = [
  {
    name: 'openTime',
    label: 'Open Time',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'magicNumber',
    label: 'Magic Number',
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: 'symbol',
    label: 'Symbol',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'size',
    label: 'Size',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'profit',
    label: 'Profit',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'percentProfit',
    label: '% Profit',
    options: {
      filter: false,
      sort: true,
    }
  }
];

const OpenOrders = () => {
  const classes = useStyles();
  const [user] = useContext(AppContext);
  const authUser = JSON.parse(user);
  const [orders, setOrders] = useState(0);
  console.log(orders);

  const options = {
    download: false,
    pagination: false,
    print: false,
    selectableRows: 'none'
  };

  const fetchOrders = async () => {
    await axios.get(`${pageData.apiUrl}/api/get-open-orders/${authUser.userId}`)
      .then((res) => {
        const data = [];
        if (res.status === 200) {
          if (res.data.orders.length === 0) {
            setOrders(0);
          } else {
            res.data.orders.map((order) => {
              data.push({
                openTime: order.openTime,
                magicNumber: order.magicNumber,
                symbol: order.symbol,
                size: order.size,
                profit: order.profit,
                percentProfit: order.percentProfit
              });
              return true;
            });
            setOrders(data);
          }
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Open Orders"
    >
      <Container maxWidth={false}>
        {
          orders === 0
            ? <NothingOpen />
            : (
              <MUIDataTable
                title="Open Orders"
                data={orders}
                columns={columns}
                options={options}
              />
            )
        }
      </Container>
    </Page>
  );
};

export default OpenOrders;
