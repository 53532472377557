import React, { useEffect, useContext } from 'react';
import {
  Box,
  Container,
  Card,
  CardContent,
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Page from 'src/components/Page';

import { AppContext } from 'src/App';
import useLocalState from 'src/utils/hooks';
import axios from 'axios';
import pageData from 'src/pageData';

import Results from './Results';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  searchBox: {
    marginRight: 30
  },
  unselectedButton: {
    marginRight: theme.spacing(1)
  },
  selectedButton: {
    marginRight: theme.spacing(1),
    color: 'black',
    background: '#e8eaf6'
  }
}));

const TransactionsListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const [data] = useContext(AppContext);
  const authUser = JSON.parse(data);

  const [transactions, setTransactions] = useLocalState('transactions');

  const fetchTransactions = async () => {
    await axios.get(`${pageData.apiUrl}/api/get-user-transactions/${authUser.userId}`)
      .then((res) => {
        const dataTransaction = [];
        if (res.status === 200) {
          res.data.transactions.map((transaction) => {
            dataTransaction.push({
              id: transaction.ticketNumber,
              ticketNumber: transaction.ticketNumber,
              magicNumber: transaction.magicNumber,
              temporality: transaction.temporality,
              orderType: transaction.orderType,
              profit: transaction.profit,
              closeTime: transaction.closeTime
            });
            return true;
          });
          setTransactions(JSON.stringify(dataTransaction));
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Transactions"
    >
      <Container maxWidth={false}>
        <div
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Box>
            <Card>
              <CardContent>
                <h3>Closed Transactions</h3>
              </CardContent>
            </Card>
          </Box>
        </div>
        <Box mt={1}>
          <Results transactions={JSON.parse(transactions)} />
        </Box>
      </Container>
    </Page>
  );
};

TransactionsListView.propTypes = {
  className: PropTypes.string
};

export default TransactionsListView;
