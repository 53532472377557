import React, { useEffect, useState, useContext } from 'react';
import MUIDataTable from 'mui-datatables';

import axios from 'axios';
import pageData from 'src/pageData';
import { AppContext } from 'src/App';

const columns = [
  {
    name: 'lastModify',
    label: 'Last Modify',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'magicNumber',
    label: 'Magic Number',
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: 'symbol',
    label: 'Symbol',
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: 'temporality',
    label: 'Temporality',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'trades',
    label: 'Trades',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'netProfit',
    label: 'Net Profit',
    options: {
      filter: false,
      sort: true,
    }
  },
];

const Tables = ({ ...rest }) => {
  const [experts, setExperts] = useState([]);
  const [user] = useContext(AppContext);
  const authUser = JSON.parse(user);

  const options = {
    download: false,
    pagination: false,
    print: false,
    selectableRows: 'none',
    onRowClick: (rowData) => rest.selectMagic(rowData[1])
  };

  const fetchExperts = async () => {
    await axios.get(`${pageData.apiUrl}/api/by-magic-number/${authUser.userId}`)
      .then((res) => {
        const dataTransaction = [];
        if (res.status === 200) {
          res.data.transactions.map((transaction) => {
            dataTransaction.push({
              magicNumber: transaction.magicNumber,
              netProfit: transaction.profit.toFixed(2),
              trades: transaction.count,
              lastModify: transaction.lastModify,
              symbol: transaction.symbol,
              temporality: transaction.temporality
            });
            return true;
          });
          setExperts(dataTransaction);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchExperts();
  }, []);

  return (
    <MUIDataTable
      title="My Statistics"
      data={experts}
      columns={columns}
      options={options}
    />
  );
};

export default Tables;
