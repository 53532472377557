import React, { useEffect, useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import MoneyIcon from '@material-ui/icons/Money';

import { AppContext } from 'src/App';
import axios from 'axios';
import pageData from 'src/pageData';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  }
}));

const OpenOrders = ({ className, ...rest }) => {
  const classes = useStyles();
  const [user] = useContext(AppContext);
  const userData = JSON.parse(user);
  const [orderCount, setOrderCount] = useState(0);
  const [lastOpen, setLastOpen] = useState(0);
  const [temporality, setTemporality] = useState(0);

  const fetchOpenOrders = async () => {
    await axios.get(`${pageData.apiUrl}/api/count-open-orders/${userData.userId}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.empty) {
            setLastOpen('Nothing Open!');
          } else {
            setOrderCount(res.data.openOrders);
            setLastOpen(res.data.lastOpen);
            setTemporality(res.data.temporality);
          }
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchOpenOrders();
  }, []);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              OPEN ORDERS
            </Typography>
            <Box ml={2}>
              <Typography
                color="textPrimary"
                variant="h3"
              >
                {orderCount}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <MoneyIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box
          mt={1}
          mb={-2}
          display="flex"
          alignItems="center"
        >
          <Typography
            variant="body1"
          >
            Last Open Order:
          </Typography>
        </Box>
        <Box
          mt={2}
          display="flex"
          alignItems="center"
        >
          <Typography
            className={classes.differenceValue}
            variant="body1"
          >
            {temporality}
          </Typography>
          <Typography
            color="textSecondary"
            variant="subtitle2"
          >
            {lastOpen}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

OpenOrders.propTypes = {
  className: PropTypes.string
};

export default OpenOrders;
