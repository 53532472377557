import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  useTheme,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Transactions = ({ className, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [width] = useState(window.innerWidth);

  const data = {
    labels: rest.times,
    datasets: [
      {
        fill: false,
        lineTension: 0.2,
        borderColor: 'rgba(0,0,0,1)',
        data: rest.profits
      }
    ]
  };

  const options = {
    animation: false,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    fillColor: 'rgba(151,187,205,0.2)',
    strokeColor: 'rgba(151,187,205,1)',
    pointColor: 'rgba(151,187,205,1)',
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true
          },
          gridLines: {
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    }
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          height={width > 500 ? 720 : 200}
          position="relative"
        >
          <Line
            data={data}
            options={options}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

Transactions.propTypes = {
  className: PropTypes.string
};

export default Transactions;
