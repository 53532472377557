import React from 'react';
import MUIDataTable from 'mui-datatables';

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

const columns = [
  {
    name: 'name',
    label: 'Name',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'price',
    label: 'Price',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'marketCap',
    label: 'Market Cap',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'revenue',
    label: 'Revenue',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'totalCash',
    label: 'Total Cash',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'totalDebts',
    label: 'Total Debts',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'weekChange',
    label: '52 Week Change',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'float',
    label: 'Float',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'options',
    label: 'Options'
  }
];

const options = {
  download: false,
  pagination: false,
  print: false
};

const Tables = ({ ...rest }) => {
  const data = [];
  rest.data.map((item) => {
    const value = {
      name: item.name,
      price: item.price,
      marketCap: item.marketCap,
      revenue: item.revenue,
      totalCash: item.totalCash,
      totalDebts: item.totalDebts,
      weekChange: item.weekChange,
      float: item.float,
      options: (
        <>
          <VisibilityOutlinedIcon
            style={{ marginRight: 5, marginLeft: 5 }}
            onClick={() => rest.viewSymbol(item)}
          />
          <EditOutlinedIcon
            style={{ marginRight: 5, marginLeft: 5 }}
            onClick={() => rest.editSymbol(item)}
          />
          <DeleteOutlineOutlinedIcon
            style={{ marginRight: 5, marginLeft: 5 }}
            onClick={() => rest.deleteSymbol(item)}
          />
        </>
      )
    };
    return data.push(value);
  });

  return (
    <MUIDataTable
      title="All Symbols"
      data={data}
      columns={columns}
      options={options}
    />
  );
};

export default Tables;
