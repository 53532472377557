import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import {
  Card,
  Box,
  CardHeader,
  Divider,
  Grid,
  colors,
  makeStyles,
  useTheme
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const TransCard = ({ className, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [title] = useState(`${rest.symbol} - ${rest.temporality}`);
  const [amountFactor] = useState(rest.amountFactor);

  const data = {
    datasets: [
      {
        data: [100 - amountFactor, amountFactor],
        backgroundColor: [
          colors.green[500],
          colors.red[600]
        ],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: ['FREE', 'USED']
  };

  const options = {
    animation: false,
    cutoutPercentage: 65,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 15,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader title={title} />
      <Divider />

      <Grid container spacing={3}>

        <Grid item xs={12}>
          <Box
            height={130}
            position="relative"
          >
            <Doughnut
              data={data}
              options={options}
            />
          </Box>
        </Grid>

      </Grid>
    </Card>
  );
};

TransCard.propTypes = {
  className: PropTypes.string
};

export default TransCard;
