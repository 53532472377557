import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import MoneyIcon from '@material-ui/icons/Money';

import axios from 'axios';
import pageData from 'src/pageData';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  }
}));

const LastTrigger = ({ className, ...rest }) => {
  const classes = useStyles();
  const [magicNumber, setMagicNumber] = useState('');
  const [symbol, setSymbol] = useState('');
  const [temporality, setTemporality] = useState('');
  const [open, setOpen] = useState('');
  const [loading] = useState(0);

  const fetchOpenOrders = async () => {
    await axios.get(`${pageData.apiUrl}/api/get-last-trigger`)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          setMagicNumber(res.data.trigger.magicNumber);
          setSymbol(res.data.trigger.symbol);
          setTemporality(res.data.trigger.temporality);
          setOpen(res.data.trigger.openTime);
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchOpenOrders();
  }, [loading]);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              LASTEST TRIGGER
            </Typography>
            <Box ml={2}>
              <Typography
                color="textPrimary"
                variant="h3"
              >
                {magicNumber}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <MoneyIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box
          mt={2}
          ml={2}
        >
          <Grid container>
            <Grid item xs={4}>
              <Typography
                variant="h5"
              >
                Symbol:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="body1"
              >
                {symbol}
                (
                {temporality}
                )
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4}>
              <Typography
                variant="h5"
              >
                Open:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="body1"
              >
                {open}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

LastTrigger.propTypes = {
  className: PropTypes.string
};

export default LastTrigger;
