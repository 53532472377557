import React, { useState, useLayoutEffect, useRef } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import pageData from '../../pageData';

const Header = () => {
  const [height, setHeight] = useState(150);
  const ref = useRef(null);

  const imgText = {
    color: 'white',
    background: 'rgba(0, 0, 0, 0.7)',
    padding: '30px',
    position: 'absolute',
    top: height < 400 ? 120 : (height / 2) - 50,
    left: 0,
    width: '100%'
  };

  useLayoutEffect(() => {
    if (ref.current.clientHeight !== null) {
      setTimeout(() => {
        setHeight(ref.current.clientHeight);
      }, 1000);
    }
  }, []);

  return (
    <div className="carousel-wrapper" ref={ref}>
      <Carousel
        infiniteLoop
        useKeyboardArrows
        autoPlay
        showThumbs={false}
        interval={5000}
        showStatus={false}
      >
        <div>
          <img
            alt="Forex backgroud"
            src="/static/images/background-forex.jpg"
          />
          <h1 style={imgText}>
            {pageData.role1}
          </h1>
        </div>
        <div>
          <img
            alt="Market backgroud"
            src="/static/images/background-market.jpg"
          />
          <h1 style={imgText}>
            {pageData.role2}
          </h1>
        </div>
        <div>
          <img
            alt="Robot backgroud"
            src="/static/images/background-robot.jpg"
          />
          <h1 style={imgText}>
            {pageData.role3}
          </h1>
        </div>
        <div>
          <img
            alt="Wall Street backgroud"
            src="/static/images/background-wall-street.jpg"
          />
          <h1 style={imgText}>
            {pageData.role4}
          </h1>
        </div>
      </Carousel>
    </div>
  );
};

export default Header;
