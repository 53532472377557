import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import pageData from '../../pageData';

const HeaderMob = () => {
  const imgText = {
    color: 'white',
    background: 'rgb(0, 0, 0, 0.7)',
    padding: '30px',
    position: 'absolute',
    top: 95,
    left: 0,
    width: '100%',
  };

  return (
    <div>
      <Carousel
        infiniteLoop
        useKeyboardArrows
        autoPlay
        showThumbs={false}
        interval={5000}
        showStatus={false}
      >
        <div>
          <img
            alt="Forex backgroud"
            src="/static/images/background-forex-mobile.jpg"
          />
          <h2 style={imgText}>
            {pageData.role1}
          </h2>
        </div>
        <div>
          <img
            alt="Market backgroud"
            src="/static/images/background-market-mobile.jpg"
          />
          <h2 style={imgText}>
            {pageData.role2}
          </h2>
        </div>
        <div>
          <img
            alt="Robot backgroud"
            src="/static/images/background-robot-mobile.jpg"
          />
          <h2 style={imgText}>
            {pageData.role3}
          </h2>
        </div>
        <div>
          <img
            alt="Wall Street backgroud"
            src="/static/images/background-wall-street-mobile.jpg"
          />
          <h2 style={imgText}>
            {pageData.role4}
          </h2>
        </div>
      </Carousel>
    </div>
  );
};

export default HeaderMob;
