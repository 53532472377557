const pageData = {
  apiUrl: 'https://uscashout.com:5000',
  name: 'U$ Cash Out',
  appLogo: '/img/full-logo-white.png',
  logoAlt: 'US Cash Out logo',
  privacyUpdate: '04/22/2020',
  role1: 'Never was to easy and secure to win your money.',
  role2: 'Take control over your finance.',
  role3: 'Let us do the bad job for you.',
  role4: 'Live Algorithmic Trading. With the best Expert Advisors on the market.',
  freetrial: 'Get started with 10 days of free services.'
};
export default pageData;
