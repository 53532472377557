import React from 'react';
import {
  Box
} from '@material-ui/core';

const NothingOpen = () => {
  return (
    <Box
      textAlign="center"
      mt={10}
    >
      <h1>You do not have any open order now...</h1>
    </Box>
  );
};

export default NothingOpen;
