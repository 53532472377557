import React, { useEffect, useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  useTheme,
  makeStyles
} from '@material-ui/core';

import axios from 'axios';
import pageData from 'src/pageData';
import { AppContext } from 'src/App';

const useStyles = makeStyles(() => ({
  root: {}
}));

const ViewExpert = ({ className, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [profits, setProfits] = useState([]);
  const [myprofits, setMyProfits] = useState([]);
  const [times, setTimes] = useState([]);
  const [userdata] = useContext(AppContext);
  const authUser = JSON.parse(userdata);

  const data = {
    labels: times,
    datasets: [
      {
        label: 'My profits',
        lineTension: 0.2,
        borderColor: 'rgba(0,0,0,1)',
        pointStrokeColor: '#fff',
        borderWidth: 1.5,
        data: myprofits,
      },
      {
        label: 'Signals',
        lineTension: 0.2,
        pointColor: 'rgba(151,187,205,1)',
        pointStrokeColor: '#fff',
        data: profits,
      }
    ]
  };

  const options = {
    animation: false,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    pointRadius: 0.2,
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true
          },
          gridLines: {
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const fetchTransactions = async () => {
    const userData = new FormData();
    userData.append('userId', authUser.userId);
    userData.append('magicNumber', rest.magic);

    await axios.post(`${pageData.apiUrl}/api/get-ea-user-transactions`, userData)
      .then((res) => {
        if (res.status === 200) {
          setMyProfits(res.data.profitList);
          setProfits(res.data.priceList);
          setTimes(res.data.timeList);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          height={650}
          position="relative"
        >
          <Line
            data={data}
            options={options}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

ViewExpert.propTypes = {
  className: PropTypes.string
};

export default ViewExpert;
