import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DvrIcon from '@material-ui/icons/Dvr';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    color: theme.palette.getContrastText('#76ff03'),
    backgroundColor: '#76ff03',
    '&:hover': {
      backgroundColor: '#64dd17'
    }
  },
}));

const GoToPortal = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Button
      variant="contained"
      size="large"
      className={classes.button}
      startIcon={<DvrIcon />}
      onClick={() => { navigate('/login', { replace: true }); }}
    >
      Go To Portal
    </Button>
  );
};

export default GoToPortal;
