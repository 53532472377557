import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Grid,
  makeStyles
} from '@material-ui/core';

import axios from 'axios';
import pageData from 'src/pageData';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';

const useStyles = makeStyles(() => ({
  root: {},
}));

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
      <Box
        mb={2}
      >
        <Card>
          <CardContent>
            <Formik
              initialValues={{
                symbol: ''
              }}
              validationSchema={Yup.object().shape({
                symbol: Yup.string().min(2).max(255).required('You need to enter a symbol.')
              })}
              onSubmit={(entryData, { resetForm }) => {
                const userData = new FormData();
                userData.append('symbol', entryData.symbol);

                axios.post(`${pageData.apiUrl}/api/add-symbol`, userData)
                  .then((res) => {
                    if (res.status === 200) {
                      if (res.data.success) {
                        ToastsStore.success(res.data.message);
                        resetForm({ entryData: '' });
                        rest.updateInserted();
                      } else {
                        ToastsStore.error(res.data.message);
                      }
                    }
                  })
                  .catch((err) => console.log(err));
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container>
                    <Grid item xs={8}>
                      <TextField
                        error={Boolean(touched.symbol && errors.symbol)}
                        fullWidth
                        helperText={touched.symbol && errors.symbol}
                        label="Add symbols"
                        margin="normal"
                        name="symbol"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.symbol || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Box my={3} ml={2}>
                        <Button
                          color="primary"
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Add Symbols
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
