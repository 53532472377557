import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from 'src/App';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';

import axios from 'axios';
import pageData from 'src/pageData';

const Results = ({ className, ...rest }) => {
  const [userData] = useContext(AppContext);
  const authUser = JSON.parse(userData);

  const [accounts, setAccounts] = useState([]);

  const fetchAccounts = async () => {
    await axios.get(`${pageData.apiUrl}/api/get-accounts/${authUser.userId}`)
      .then((res) => {
        const data = [];
        if (res.status === 200) {
          res.data.accounts.map((account) => {
            data.push({
              description: account.description,
              lastModify: account.lastModify,
              accountNumber: account.accountNumber,
              freeMargin: `$ ${Math.round(account.freeMargin)}`
            });
            return true;
          });
          setAccounts(data);
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchAccounts();
  }, []);

  return (
    <Card
      {...rest}
    >
      <PerfectScrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ textAlign: 'center' }}>
                  Account ID
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  Free Margin
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  Last Modify
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accounts.map((account) => (
                <TableRow
                  hover
                  key={account.id}
                >
                  <TableCell style={{ textAlign: 'center' }}>
                    {account.description}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    {account.freeMargin}
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    {/* {moment(account.lastModify).format('MM/DD HH:mm')} */}
                    {moment(account.lastModify).fromNow()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
