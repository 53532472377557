import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography
} from '@material-ui/core';

const Footer = () => {
  return (
    <AppBar position="static" color="primary">
      <Toolbar>
        <Typography variant="body1" color="inherit">
          © 2020 Copyright:
          <a style={{ color: 'white' }} href="https://uscashout.com">&nbsp;&nbsp;&nbsp; https://uscashout.com</a>
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
