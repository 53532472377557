import React from 'react';
import MUIDataTable from 'mui-datatables';

const columns = [
  {
    name: 'lastModify',
    label: 'Last Modify',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'magicNumber',
    label: 'Magic Number',
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: 'symbol',
    label: 'Symbol',
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: 'temporality',
    label: 'Temporality',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'trades',
    label: 'Trades',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'winners',
    label: 'Winners',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'losers',
    label: 'Losers',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'netProfit',
    label: 'Net Profit',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'profitFactor',
    label: 'Profit Factor',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'followers',
    label: 'Followers',
    options: {
      filter: false,
      sort: true,
    }
  },
];

const Tables = ({ ...rest }) => {
  const options = {
    download: false,
    pagination: false,
    print: false,
    selectableRows: 'none',
    onRowClick: (rowData) => rest.selectMagic(rowData[1])
  };

  return (
    <MUIDataTable
      title="All Expert Advisors"
      data={rest.data}
      columns={columns}
      options={options}
    />
  );
};

export default Tables;
