import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';

import axios from 'axios';
import pageData from 'src/pageData';

import TransCard from './TransCard';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const [translog, setTranslog] = useState([]);

  const fetchByTemporality = async () => {
    await axios.get(`${pageData.apiUrl}/api/get-translog`)
      .then((res) => {
        if (res.status === 200) {
          setTranslog(res.data.translogs);
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchByTemporality();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Transactions"
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          {translog.length > 0 && translog.map((item) => (
            <Grid
              item
              sm={4}
              lg={3}
              xl={2}
              xs={6}
            >
              <TransCard
                symbol={item.symbol}
                temporality={item.temporality}
                amountFactor={Math.floor(item.amountFactor)}
              />
            </Grid>
          ))}

        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
