import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';

import axios from 'axios';
import pageData from 'src/pageData';
import { AppContext } from 'src/App';
import useLocalState from 'src/utils/hooks';

import Page from 'src/components/Page';
import Tables from './Tables';
// import TableFix from './TableFix';
import ViewExpert from './ViewExpert';
import Toolbar from './Toolbar';
import MainToolbar from './MainToolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(3)
  }
}));

const CustomerListView = () => {
  const classes = useStyles();
  const [data] = useContext(AppContext);
  const authUser = JSON.parse(data);

  const [magic, setMagic] = useState(0);
  const [experts, setExperts] = useState([]);
  console.log(experts);

  const [symbol, setSymbol] = useLocalState('EURUSD');
  const [temporality, setTemporality] = useLocalState('M15');
  const [trades, setTrades] = useState('10');
  const [profitFactor, setProfitFactor] = useState('50');

  const handleSelectMagic = (magicSelected) => {
    setMagic(magicSelected);
  };

  const handleSearchMagic = async (text) => {
    if (text.length >= 3) {
      const userData = new FormData();
      userData.append('userId', authUser.userId);

      await axios.post(`${pageData.apiUrl}/api/search-by-magic/${text}`, userData)
        .then((res) => {
          const eas = [];
          if (res.status === 200) {
            res.data.statistics.map((ea) => {
              const contained = ea.followers.filter((item) => item.userId === authUser.userId);
              if (contained.length === 0) { // Check if the user already follow this EA
                eas.push({
                  lastModify: ea.lastModify,
                  magicNumber: ea.magicNumber,
                  symbol: ea.symbol,
                  temporality: ea.temporality,
                  trades: ea.trades,
                  winners: ea.winners,
                  losers: ea.losers,
                  netProfit: ea.netProfit.toFixed(2),
                  followers: ea.followers.length,
                  profitFactor: `${ea.profitFactor} %`
                });
                return true;
              }
              return true;
            });
            setExperts(eas);
          }
        })
        .catch((err) => console.log(err));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  };

  const handleViewResult = async () => {
    const userData = new FormData();
    userData.append('userId', authUser.userId);
    userData.append('symbol', symbol);
    userData.append('temporality', temporality);
    userData.append('trades', trades);
    userData.append('profitFactor', profitFactor);

    await axios.post(`${pageData.apiUrl}/api/get-ea-statistics-by-options`, userData)
      .then((res) => {
        const eas = [];
        if (res.status === 200) {
          res.data.statistics.map((ea) => {
            const contained = ea.followers.filter((item) => item.userId === authUser.userId);
            if (contained.length === 0) { // Check if the user already follow this EA
              eas.push({
                lastModify: ea.lastModify,
                magicNumber: ea.magicNumber,
                symbol: ea.symbol,
                temporality: ea.temporality,
                trades: ea.trades,
                winners: ea.winners,
                losers: ea.losers,
                netProfit: ea.netProfit.toFixed(2),
                followers: ea.followers.length,
                profitFactor: `${ea.profitFactor} %`
              });
              return true;
            }
            return true;
          });
          setExperts(eas);
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleBySymbol = (text) => {
    setSymbol(text);
  };

  const handleByTemporality = (text) => {
    setTemporality(text);
  };

  const handleByTrades = (text) => {
    setTrades(text);
  };

  const handleByProfitFactor = (text) => {
    setProfitFactor(text);
  };

  useEffect(() => {
    handleViewResult();
  }, [symbol, temporality, trades, profitFactor]);

  const closeSelected = () => {
    setMagic(0);
    handleViewResult();
  };

  return (
    <Page
      className={classes.root}
      title="Experts Advisors"
    >
      <Container maxWidth={false}>
        {magic === 0 ? (
          <Box>
            <MainToolbar
              searchmagic={(text) => handleSearchMagic(text)}
              searchbysymbol={(text) => handleBySymbol(text)}
              searchbytemporality={(text) => handleByTemporality(text)}
              searchbytrades={(text) => handleByTrades(text)}
              searchbyprofitfactor={(text) => handleByProfitFactor(text)}
            />
            <Tables
              data={experts}
              selectMagic={(magicSelected) => handleSelectMagic(magicSelected)}
            />
            {/* <TableFix selectMagic={(magicSelected) => handleSelectMagic(magicSelected)} /> */}
          </Box>
        )
          : (
            <Box>
              <Toolbar magic={magic} closeMagic={() => closeSelected()} />
              <ViewExpert magic={magic} />
            </Box>
          )}
      </Container>
    </Page>
  );
};

export default CustomerListView;
