import React, { useEffect, useState, useContext } from 'react';
import MUIDataTable from 'mui-datatables';

import axios from 'axios';
import pageData from 'src/pageData';
import { AppContext } from 'src/App';

const columns = [
  {
    name: 'lastModify',
    label: 'Last Modify',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'magicNumber',
    label: 'Magic Number',
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: 'symbol',
    label: 'Symbol',
    options: {
      filter: false,
      sort: false,
    }
  },
  {
    name: 'temporality',
    label: 'Temporality',
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: 'trades',
    label: 'Trades',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'winners',
    label: 'Winners',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'losers',
    label: 'Losers',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'netProfit',
    label: 'Net Profit',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'profitFactor',
    label: 'Profit Factor',
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: 'followers',
    label: 'Followers',
    options: {
      filter: false,
      sort: true,
    }
  },
];

const Tables = ({ ...rest }) => {
  const [experts, setExperts] = useState([]);
  const [user] = useContext(AppContext);
  const authUser = JSON.parse(user);

  const options = {
    download: false,
    pagination: false,
    print: false,
    selectableRows: 'none',
    onRowClick: (rowData) => rest.selectMagic(rowData[1])
  };

  const fetchExperts = async () => {
    await axios.get(`${pageData.apiUrl}/api/get-ea-statistics`)
      .then((res) => {
        const data = [];
        if (res.status === 200) {
          res.data.statistics.map((ea) => {
            const contained = ea.followers.filter((item) => item.userId === authUser.userId);
            if (contained.length === 0) { // Check if the user already follow this EA
              data.push({
                lastModify: ea.lastModify,
                magicNumber: ea.magicNumber,
                symbol: ea.symbol,
                temporality: ea.temporality,
                trades: ea.trades,
                winners: ea.winners,
                losers: ea.losers,
                netProfit: ea.netProfit.toFixed(2),
                followers: ea.followers.length,
                profitFactor: `${ea.profitFactor} %`
              });
              return true;
            }
            return true;
          });
          setExperts(data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchExperts();
  }, []);

  return (
    <MUIDataTable
      title="All Expert Advisors"
      data={experts}
      columns={columns}
      options={options}
    />
  );
};

export default Tables;
