import React, { useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Tables from './Tables';
import ViewExpert from './ViewExpert';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(3)
  }
}));

const StatisticListView = () => {
  const classes = useStyles();
  const [magic, setMagic] = useState(0);

  const handleSelectMagic = (magicSelected) => {
    setMagic(magicSelected);
  };

  const closeSelected = () => {
    setMagic(0);
  };

  return (
    <Page
      className={classes.root}
      title="All Expert Advisors"
    >
      <Container maxWidth={false}>
        {magic === 0 ? (
          <Box>
            <Tables selectMagic={(magicSelected) => handleSelectMagic(magicSelected)} />
          </Box>
        )
          : (
            <Box>
              <Toolbar magic={magic} closeMagic={() => closeSelected()} />
              <ViewExpert magic={magic} />
            </Box>
          )}
      </Container>
    </Page>
  );
};

export default StatisticListView;
