import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {},
  buttonOpositive: {
    color: '#3e2723',
    background: '#efebe9',
    marginLeft: theme.spacing(1)
  },
  buttonSame: {
    color: '#0d47a1',
    background: '#e3f2fd',
    marginLeft: theme.spacing(1)
  },
  buttonClose: {
    color: '#b71c1c',
    background: '#fce4ec',
    marginLeft: theme.spacing(1)
  }
}));

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        mb={3}
      >
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <h1>{`Magic Number: ${rest.magic}`}</h1>
              </Grid>
              <Grid
                item
                xs={6}
              >
                <Box
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Button
                    className={classes.buttonClose}
                    startIcon={<CloseIcon />}
                    onClick={() => rest.closeMagic()}
                  >
                    CLOSE
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
